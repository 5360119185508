import React, { useContext, useState } from "react";
import pai from "../assets/images/pai.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../assets/images/logo.png";
import carpicture from "../assets/images/carpicture.jpg";
import { FormContext } from "../App";


const Finalstep = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingpre, setIsLoadingpre] = useState(false);

  const handleButtonClick = () => {

    setIsLoading(true);
    axios
      .post("https://cleancarclaims.co.uk/mongo/api/cardetails", formData)
      .then((response) => {
        window.alert("Thank You\nWe have received your enquiry and we will respond within the next 48 hours. For any issues, please email us at help@cleancarclaims.co.uk.");
        setFormData({});
        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 500);
        navigate("/");

      })
      .catch((error) => console.error(error));

  };
  const goPrevious = () => {
    setIsLoadingpre(true)
    setTimeout(() => {
      setIsLoadingpre(false);
      navigate('/AddressDetails')
    },200);
  };
  return (
    <div className="maincontainer">
      <div className="logodiv">
        <img src={logo} className="logo1" alt="logo" />
      </div>
      <div className="yellowdiv"></div>
      <div className="carpicturediv">
        <img src={carpicture} className="carpicture" alt="carpicture" />
      </div>
      <div className="detailMainContainer">
        <div className="progress-container">
          <p className="step-indicator">Step 3 of 3</p>
          <div className="progress-bar">
            <div className="progress" style={{ width: "100%" }}>
              100%
            </div>
          </div>
        </div>
        <div className="detailcontainer">
          <img src={pai} className="personimg" alt="pai" />
          <div className="finalstepdiv">
            <p className="detailsText" >Final step...</p>
          </div>
        </div>
        <div className="introdiv">
          <p className="introText">
            Register your details below to be added to the class of potential
            claimants.
          </p>
          <p className="introText">
            All Personal data will be processed in accordance with the Data
            Protection (Jersey) Law 2018 (and equivalent laws and regulations in
            Guemsey and the Isle of Man).
          </p>
        </div>
        <div className="bothbuttondiv">
          <button className="previewbutton" onClick={() => goPrevious()}>
            {isLoadingpre ? <div className="loader"></div> : <p className="continuetext" style={{ marginTop: 0 }}>Previous</p>}

          </button>
          <button className="nextbutton" onClick={() => handleButtonClick()}>
            {isLoading ? <div className="loader"></div> : <p className="continuetext" style={{ marginTop: 0 }}>Submit Your Claim</p>}
          </button>
        </div>
      </div>
      <div className="yellowdiv"></div>
    </div>
  );
};
export default Finalstep;
