import React, { useState, useContext, useEffect } from "react";
import arrow from "../assets/images/arrow.png";
import logo from "../assets/images/logo.png";
import carpicture from "../assets/images/carpicture.jpg";
import { useNavigate } from "react-router-dom";
import { FormContext } from "../App";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { formData, setFormData } = useContext(FormContext);
  const [carmodal, setcarmodal] = useState("");
  const [date, setdate] = useState("");
  const [pay, setpay] = useState("");
  const carnumber = formData?.carnumber || "";
  const [isLoading, setIsLoading] = useState(false);

  const [errors, setErrors] = useState({
    carmodal: "",
    date: "",
    pay: "",
  });

  useEffect(() => {
    if (formData) {
      const { carmodal, date, pay } = formData;
      setdate(date);
      setcarmodal(carmodal);
      setpay(pay);
    }
  }, [formData]);

  const validateForm = () => {
    let valid = true;
    let newErrors = { carmodal: "", date: "", pay: "" };

    if (!carmodal) {
      newErrors.carmodal = "Car make and model is required.";
      valid = false;
    }

    if (!date) {
      newErrors.date = "Date is required.";
      valid = false;
    }

    if (!pay) {
      newErrors.pay = "Payment method is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };


  const handleButtonClick = () => {
    if (validateForm()) {
      setIsLoading(true); 
      setFormData({ ...formData, carmodal, date, pay });
      setTimeout(() => {
        setIsLoading(false); 
        navigate("/Details", { state: formData });
      }, 1000);
    }
  };


  const handleDateChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9/]/g, "");

    if (value.length === 5 && value[4] === "/") {
      value = value.slice(0, 4);
    } else if (value.length === 2 && !value.includes("/")) {
      value = value + "/";
    } else if (value.length === 3 && value[2] === "/") {
      value = value.slice(0, 2);
    }
    if (value.length > 7) {
      value = value.slice(0, 7);
    }

    setdate(value);
    if (value) {
      setErrors((prevErrors) => ({ ...prevErrors, date: "" }));
    }
  };

  return (
    <div className="maincontainer">
      <div className="logodiv">
        <img src={logo} className="logo1" alt="logo" />
      </div>
      <div className="yellowdiv"></div>
      <div className="carpicturediv">
        <img src={carpicture} className="carpicture" alt="carpicture" />
      </div>
      <div className="container">
        <div className="main-div-register">
          {/* <div className="mapdiv2">
            <img src={map} className="map2" alt="map" />
            <p className="GB2">GB</p>
          </div> */}
          <div className="carnumber">
            <p className="carnumbertext">{carnumber}</p>
          </div>
        </div>
        <div className="seconddiv2">
          <div className="arrowdiv2">
            <img src={arrow} className="arrowlogo" alt="arrow" />
            <p className="arrowtext">Car Make and Model :</p>
          </div>
          <div className="inputyeardiv">
          <input
                value={carmodal}
                placeholder="Enter Car Modal Number"
                className="carmodalinput3"
                onChange={(e) => {
                  setcarmodal(e.target.value.toUpperCase());
                  if (e.target.value) {
                    setErrors((prevErrors) => ({ ...prevErrors, carmodal: "" }));
                  }
                }}
                onBlur={() => {
                  if (!carmodal) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      carmodal: "Car make and model is required.",
                    }));
                  }
                }}
              />
          </div>
          {errors.carmodal && (
            <p className="carmodaltext">{errors.carmodal}</p>
          )}

          <div className="arrowdiv2">
            <img src={arrow} className="arrowlogo" alt="arrow" />
            <p className="arrowtext">When did you acquire the vehicle?</p>
          </div>
          <div className="inputyeardiv">
            <input
              value={date}
              placeholder="MM/YYYY"
              className="inputyear"
              onChange={handleDateChange}
              onBlur={() => {
                if (!date) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    date: "Date is required.",
                  }));
                }
              }}
            />
            {errors.date && <p className="fnameError">{errors.date}</p>}
          </div>
          <div className="arrowdiv">
            <img src={arrow} className="arrowlogo" alt="arrow" />
            <p className="arrowtext">When did you pay for this vehicle?</p>
          </div>
          <div className="radio-group">
            <label className={`custom-radio ${pay === "Cash" ? "selected" : ""}`}>
              <input
                type="radio"
                name="options"
                value="Cash"
                checked={pay === "Cash"}
                onChange={(e) => {
                  setpay(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, pay: "" }));
                }}
              />
              <span className="radio-text">Cash</span>
            </label>
            <label className={`custom-radio ${pay === "HP" ? "selected" : ""}`}>
              <input
                type="radio"
                name="options"
                value="HP"
                checked={pay === "HP"}
                onChange={(e) => {
                  setpay(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, pay: "" }));
                }}
              />
              <span className="radio-text">HP(Hire Purchase)</span>
            </label>
            <label className={`custom-radio ${pay === "PCP" ? "selected" : ""}`}>
              <input
                type="radio"
                name="options"
                value="PCP"
                checked={pay === "PCP"}
                onChange={(e) => {
                  setpay(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, pay: "" }));
                }}
              />
              <span className="radio-text">
                PCP(Personal Contract Purchase)
              </span>
            </label>
            <label className={`custom-radio ${pay === "Other" ? "selected" : ""}`}>
              <input
                type="radio"
                name="options"
                value="Other"
                checked={pay === "Other"}
                className="radiobutton"
                onChange={(e) => {
                  setpay(e.target.value);
                  setErrors((prevErrors) => ({ ...prevErrors, pay: "" }));
                }}
              />
              <span className="radio-text">Other</span>
            </label>
          </div>
          {errors.pay && <p className="fnameError">{errors.pay}</p>}
          <div className="buttoncontainer">
            <button onClick={handleButtonClick} className="continuebutton" disabled={isLoading}>
              {isLoading ? <div className="loader"></div> : <p className="continuetext" style={{marginTop:0}}>Continue</p>}
            </button>
          </div>
        </div>
      </div>
      <div className="yellowdiv"></div>
    </div>
  );
};

export default RegisterPage;
